var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("b-table", {
        staticClass: "ana-table",
        attrs: {
          fields: _vm.fieldsAnalytics,
          items: _vm.items,
          "primary-key": "exIP.ip",
          "tbody-transition-props": _vm.transProps,
          striped: "",
          bordered: "",
          hover: "",
          "sticky-header": "70vh"
        },
        scopedSlots: _vm._u([
          {
            key: "cell(deviceType)",
            fn: function(data) {
              return [
                _c("device-icon", {
                  attrs: {
                    deviceType: data.value.deviceType,
                    deviceOS: data.value.deviceOS
                  }
                })
              ]
            }
          },
          {
            key: "cell(country)",
            fn: function(data) {
              return [
                _c("ul", { staticClass: "table-country-list" }, [
                  _c(
                    "li",
                    [
                      data.value.country != ""
                        ? _c("country-flag", {
                            attrs: { country: data.value.country }
                          })
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm.$acl.get[0] === "user"
                    ? _c("li", { staticClass: "table-country-city" }, [
                        _c("span", [_vm._v("*****")])
                      ])
                    : _c("li", { staticClass: "table-country-city" }, [
                        !data.value.city.includes("undefined")
                          ? _c("span", [_vm._v(_vm._s(data.value.city))])
                          : _vm._e()
                      ])
                ])
              ]
            }
          },
          {
            key: "cell(exIP)",
            fn: function(data) {
              return [
                _c("ul", { staticClass: "table-ip-list" }, [
                  data.value.ranking == 100
                    ? _c("li", { staticClass: "table-ip-ip text-danger" }, [
                        _vm.$acl.get[0] === "user"
                          ? _c("span", [_vm._v(_vm._s(data.value.ipFree))])
                          : _c("span", [_vm._v(_vm._s(data.value.ip))])
                      ])
                    : _c("li", { staticClass: "table-ip-ip text-success" }, [
                        _vm.$acl.get[0] === "user"
                          ? _c("span", [_vm._v(_vm._s(data.value.ipFree))])
                          : _c("span", [_vm._v(_vm._s(data.value.ip))])
                      ]),
                  _c("li", { staticClass: "table-ip-date" }, [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm
                            ._f("moment")(data.value.date)
                            .format("HH:mm:ss DD-MM-YYYY")
                        )
                      )
                    ])
                  ])
                ])
              ]
            }
          },
          {
            key: "table-caption",
            fn: function() {
              return [
                _c(
                  "b-col",
                  { staticClass: "my-1", attrs: { md: "6" } },
                  [
                    _c(
                      "b-button",
                      {
                        attrs: {
                          id: "showMoreTraffic",
                          variant: "primary",
                          hidden: _vm.firstElement ? null : true
                        },
                        on: { click: _vm.getItemsProvider }
                      },
                      [_vm._v("Mostrar más")]
                    )
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }