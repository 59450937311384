<template>
  <div>
    <b-table class="ana-table" :fields="fieldsAnalytics" :items="items" primary-key="exIP.ip" :tbody-transition-props="transProps" striped bordered hover sticky-header="70vh">
      <template v-slot:cell(deviceType)="data">
        <device-icon :deviceType="data.value.deviceType" :deviceOS="data.value.deviceOS"></device-icon>
      </template>
      <template v-slot:cell(country)="data">
        <ul class="table-country-list">
          <li>
            <country-flag v-if="data.value.country != ''" :country="data.value.country"></country-flag>
          </li>
          <li v-if="$acl.get[0]==='user'" class="table-country-city">
            <span>*****</span>
          </li>
          <li v-else class="table-country-city">
            <span v-if="!data.value.city.includes('undefined')">{{ data.value.city }}</span>
          </li>
        </ul>
      </template>
      <template v-slot:cell(exIP)="data">
        <ul class="table-ip-list">
          <li v-if="data.value.ranking == 100" class="table-ip-ip text-danger">
            <span v-if="$acl.get[0]==='user'">{{ data.value.ipFree}}</span>
            <span v-else>{{ data.value.ip }}</span>
          </li>
          <li v-else class="table-ip-ip text-success">
            <span v-if="$acl.get[0]==='user'">{{ data.value.ipFree }}</span>
            <span v-else>{{ data.value.ip }}</span>
          </li>
          <li class="table-ip-date">
            <span>{{
              data.value.date | moment().format("HH:mm:ss DD-MM-YYYY")
            }}</span>
          </li>
        </ul>
      </template>
      <template v-slot:table-caption>
        <b-col md="6" class="my-1">
          <b-button id="showMoreTraffic" variant="primary" @click="getItemsProvider" :hidden="firstElement ? null : true">Mostrar más</b-button>
        </b-col>
      </template>
    </b-table>
  </div>
</template>

<script>
import CountryFlag from "vue-country-flag"
import DeviceIcon from "@/components/DeviceIcon.vue"
import Amplify from "aws-amplify"
import { EventBus } from '@/event-bus'
import utils from '@/api/utils.js'

export default {
  data() {
    return {
      transProps: {
        name: 'flip-list'
      },
      firstElement: null,
      perPage: 25,
      sortBy: '',
      rowSel: false,
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      ipSelectec: '',
      filterOn: [],
      items: [],
      fieldsAnalytics: [
        {
          key: "deviceType",
          label: "Dispositivo",
          formatter: (value, key, item) => {
            return { deviceType: value, deviceOS: item.deviceOS }
          },
          class: "table-device-col"
        },
        {
          key: "exIP",
          label: "IP & Fecha",
          formatter: (value, key, item) => {
            return { ip: value, date: item.createdAt, ranking: item.ranking, ipFree: item.ipFree }
          },
          class: "table-ip-col"
        },
        {
          key: "country",
          label: "Localización",
          formatter: (value, key, item) => {
            let countryClean = ''
            if (value) {
              countryClean = value
            }
            return { country: countryClean, city: item.city }
          },
          class: "table-country-col"
        },
        {
          key: "nClicks",
          label: "Clics",
          class: "table-nclicks-col"
        },
        {
          key: "campaignName",
          label: "Campaña",
          class: "table-campaign-col"
        },
        {
          key: "gKeyword",
          label: "Palabra clave",
          class: "table-keyword-col"
        }
      ],
    };
  },
  methods: {
    async initialGetUserData(dataUserId) {
      await this.$initMainData(dataUserId)
      this.getItemsProvider()
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    onRowSelected(items) {
      if (items.length > 0) {
        this.rowSel = true
        this.ipSelectec = items[0].exIP
      } else {
        this.rowSel = false
        this.ipSelectec = ''
      }
    },
    formatDevice(value) {
      return { deviceType: value.type, deviceOS: value.os }
    },
    formatIP(value) {
      return value.ip + " " + value.date
    },
    transformDataFormat(ctx) {
      let dataItems = { items: [] }
      for (let i = 0; i < ctx.length; i++) {
        let device = {
          devicetype: ctx[i]["cli.uaPlaTyp"],
          deviceos: ctx[i]["cli.uaOsNam"]
        }
        let ipHide = {
          ipTotal: ctx[i]["cli.ip"],
          ipSplit: undefined
        }
        if (device.devicetype !== undefined && device.deviceos !== undefined) {
          device.devicetype = device.devicetype.toLowerCase()
          device.deviceos = device.deviceos.toLowerCase()
        } else {
          device.devicetype = ''
          device.deviceos = ''
        }
        if (ipHide.ipTotal !== undefined) {
          ipHide.ipSplit = ipHide.ipTotal.split('.')
        } else {
          ipHide.ipSplit = ['*']
        }
        dataItems.items.push({
          deviceType: device.devicetype,
          deviceOS: device.deviceos,
          exIP: ctx[i]["cli.ip"],
          createdAt: ctx[i]["timestamp"],
          ipFree: ipHide.ipSplit[0] + ".*.*.*",
          country: ctx[i]["cli.gPhiCouCod"],
          city: ctx[i]["cli.gPhiCityNam"] + " (" + ctx[i]["cli.gPhiCouNam"] + ") ",
          nClicks: ctx[i]["cli.nClAccIp"],
          ranking: ctx[i]["cli.score"],
          campaignName: ctx[i]["cli.camName"],
          gKeyword: ctx[i]["cli.gKeyword"]
        });
      }
      return dataItems.items
    },
    getItemsProvider() {
      this.$vs.loading()
      Amplify.Auth.currentSession().then(apiSession => {
        // INI Get clicks
        let paramsClick = {
          numItems: this.perPage,
          firstItem: this.firstElement
        }
        this.$axios
          .post(`/clicks/${this.$store.state.user.mainAccount}`, paramsClick, {
            headers: {
              Authorization: "Bearer " + apiSession.idToken.jwtToken
            }
          })
          .then(response => {
            const results = response.data
            const dataitems = this.transformDataFormat(results.items)
            this.items = this.items.concat(dataitems)
            this.$vs.loading.close()
            this.firstElement = results.lastIndex
          })
        return null
      });
    }
  },
  mounted() {
    EventBus.$on('update-traffic', () => {
      this.items.length = 0
      this.firstElement = null
      this.getItemsProvider()
    })
    Amplify.Auth.currentSession().then(apiSession => {
      const paramsCheckAdmin = apiSession.accessToken.payload['cognito:groups']
      this.$store.dispatch('updateAdmin', utils.isAdminUser(paramsCheckAdmin))
      if (this.$store.state.user.isAdmin && this.$store.state.user.mainAccount !== '') {
        this.initialGetUserData(this.$store.state.user.id)
        this.$acl.change('admin')
      } else if (this.$store.state.user.isAdmin && this.$store.state.user.mainAccount === '') {
        this.$vs.loading.close()
        this.$acl.change('admin')
        this.$router.push('/admin-page')
      } else {
        this.initialGetUserData('0')
        this.$vs.loading.close()
      }
    })
  },
  components: {
    CountryFlag,
    DeviceIcon
  }
}
</script>
<style>
table.ana-table .flip-list-move {
  transition: transform 1s;
}
.ana-table th {
  font-size: 1.3rem;
  text-align: center;
}
.ana-table td {
  vertical-align: middle;
  text-align: center;
}
.table-country-list {
  display: flex;
  margin-bottom: 0 !important;
}
.table-country-list li:first-child {
  width: 33%;
}
.table-country-list li:first-child + li {
  width: 66%;
}
.table-ip-ip {
  font-size: 1.2rem;
  font-weight: bold;
  color: #626262;
}
.table-ip-date {
  color: #626262;
}
.table-device-col {
  width: 8%;
}
.table-ip-col {
  width: 22%;
}
.table-country-col {
  width: 19%;
}
.table-nclicks-col {
  width: 5%;
}
.table-campaign-col {
  width: 22%;
}
.table-keyword-col {
  width: 22%;
}
.filterpos {
  justify-content: flex-end;
}
.filterinput {
  font-size: 1.2rem;
}
.filterinput > div > button:not(:disabled) {
  background-color: #2f1b7a;
}
.firstinput {
  padding: 15px 0px !important;
  border-top-left-radius: 20px !important;
  border-bottom-left-radius: 20px !important;
  text-align: center;
}
.secondinput {
  padding: 0px 0px 10px 0px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}
.buttonclear {
  padding: 0.375rem 0.75rem !important;
}
</style>