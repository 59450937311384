<template>
  <div>
    <div v-if="deviceType == 'mobile' && deviceOS == 'android'">
      <svg class="feather table-icon-device" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" xmlns:cc="http://creativecommons.org/ns#" xmlns:dc="http://purl.org/dc/elements/1.1/" xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#">
        <g transform="matrix(3.7795 0 0 3.7795 970.39 -286.77)">
          <g class="icon-device" transform="matrix(.26458 0 0 .26458 -256.75 75.874)" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2">
            <rect x="5" y="2" width="14" height="20" rx="2" ry="2" />
            <line x1="12" x2="12" y1="18" y2="18" />
          </g>
          <g transform="matrix(.0035256 0 0 .0035256 -254.65 77.388)">
            <path d="m152.68 458.36c0 15.3 10.2 25.5 25.5 25.5h25.5v89.25c0 20.4 17.85 38.25 38.25 38.25s38.25-17.85 38.25-38.25v-89.25h51v89.25c0 20.4 17.85 38.25 38.25 38.25s38.25-17.85 38.25-38.25v-89.25h25.5c15.3 0 25.5-10.2 25.5-25.5v-255h-306zm-63.75-255c-20.4 0-38.25 17.851-38.25 38.25v178.5c0 20.4 17.85 38.25 38.25 38.25s38.25-17.85 38.25-38.25v-178.5c0-20.4-17.85-38.25-38.25-38.25zm433.5 0c-20.4 0-38.25 17.851-38.25 38.25v178.5c0 20.4 17.85 38.25 38.25 38.25s38.25-17.85 38.25-38.25v-178.5c0-20.4-17.85-38.25-38.25-38.25zm-127.5-147.9 33.15-33.15c5.1-5.1 5.1-12.75 0-17.85-5.101-5.101-12.75-5.101-17.851 0l-38.25 38.25c-17.85-12.75-40.8-17.851-66.3-17.851s-48.45 5.101-68.85 15.3l-35.7-38.25c-5.1-2.55-15.3-2.55-20.4 0-2.55 5.101-2.55 15.301 0 20.4l33.15 33.15c-35.7 28.05-61.2 71.399-61.2 122.4h306c1e-3 -50.998-25.499-96.898-63.749-122.4zm-140.25 71.4h-25.5v-25.5h25.5zm127.5 0h-25.5v-25.5h25.5z" />
          </g>
        </g>
      </svg>
    </div>
    <div v-else-if="deviceType == 'mobile' && deviceOS == 'apple'">
      <svg class="feather table-icon-device" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" xmlns:cc="http://creativecommons.org/ns#" xmlns:dc="http://purl.org/dc/elements/1.1/" xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#">
        <g transform="matrix(3.7795 0 0 3.7795 970.93 -279.09)">
          <g class="icon-device" transform="matrix(.26458 0 0 .26458 -256.89 73.843)" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2">
            <rect x="5" y="2" width="14" height="20" rx="2" ry="2" />
            <line x1="12" x2="12" y1="18" y2="18" />
          </g>
          <g transform="matrix(.011641 0 0 -.011641 -256.87 80.098)" stroke="none">
            <path d="m310.46 382.58c-15.106 1.119-27.922-8.435-35.071-8.435-7.25 0-18.437 8.191-30.291 7.968-15.576-0.226-29.923-9.061-37.955-23.002-16.172-28.069-4.125-69.661 11.626-92.444 7.703-11.134 16.893-23.677 28.967-23.216 11.621 0.461 16.014 7.514 30.058 7.514 14.035 0 17.994-7.514 30.283-7.29 12.508 0.237 20.429 11.362 28.08 22.549 8.851 12.922 12.486 25.437 12.705 26.097-0.277 0.104-24.375 9.352-24.621 37.116-0.215 23.216 18.951 34.377 19.824 34.914-10.88 16.022-27.689 17.788-33.605 18.229" />
            <path d="m301.14 397.61c6.412 7.751 10.731 18.552 9.541 29.287-9.229-0.362-20.398-6.146-27.017-13.895-5.934-6.862-11.143-17.856-9.725-28.376 10.291-0.796 20.794 5.227 27.201 12.984" />
            <path d="m305.32 379c-15.216 1.128-28.132-8.503-35.334-8.503-7.312 0-18.578 8.26-30.528 8.035-15.686-0.23-30.147-9.132-38.236-23.177-16.296-28.284-4.16-70.196 11.714-93.15 7.759-11.218 17.024-23.852 29.186-23.392 11.704 0.457 16.137 7.566 30.287 7.566 14.144 0 18.134-7.566 30.514-7.338 12.601 0.229 20.584 11.446 28.29 22.726 8.918 13.016 12.588 25.621 12.803 26.284-0.281 0.1-24.559 9.423-24.805 37.401-0.22 23.392 19.09 34.637 19.973 35.181-10.968 16.142-27.899 17.926-33.864 18.367" />
            <path d="m295.92 394.15c6.464 7.812 10.814 18.692 9.62 29.515-9.308-0.369-20.557-6.199-27.232-14.005-5.974-6.917-11.223-17.994-9.796-28.591 10.366-0.805 20.952 5.266 27.408 13.081" />
          </g>
        </g>
      </svg>
    </div>
    <div v-else-if="deviceType == 'mobile'">
      <svg class="feather table-icon-device" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" xmlns:cc="http://creativecommons.org/ns#" xmlns:dc="http://purl.org/dc/elements/1.1/" xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#">
        <g class="icon-device" transform="translate(-4.3568e-6 -1.2899e-6)" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2">
          <rect x="5" y="2" width="14" height="20" rx="2" ry="2" />
          <line x1="12" x2="12" y1="18" y2="18" />
        </g>
      </svg>
    </div>
    <div v-if="(deviceType == 'pc' || deviceType == 'desktop') && deviceOS == 'apple'">
      <svg class="feather table-icon-device" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" xmlns:cc="http://creativecommons.org/ns#" xmlns:dc="http://purl.org/dc/elements/1.1/" xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#">
        <g transform="matrix(3.7795 0 0 3.7795 961.46 -290.34)">
          <g transform="matrix(.011641 0 0 -.011641 -254.37 83.335)" stroke="none">
            <path d="m310.46 382.58c-15.106 1.119-27.922-8.435-35.071-8.435-7.25 0-18.437 8.191-30.291 7.968-15.576-0.226-29.923-9.061-37.955-23.002-16.172-28.069-4.125-69.661 11.626-92.444 7.703-11.134 16.893-23.677 28.967-23.216 11.621 0.461 16.014 7.514 30.058 7.514 14.035 0 17.994-7.514 30.283-7.29 12.508 0.237 20.429 11.362 28.08 22.549 8.851 12.922 12.486 25.437 12.705 26.097-0.277 0.104-24.375 9.352-24.621 37.116-0.215 23.216 18.951 34.377 19.824 34.914-10.88 16.022-27.689 17.788-33.605 18.229" />
            <path d="m301.14 397.61c6.412 7.751 10.731 18.552 9.541 29.287-9.229-0.362-20.398-6.146-27.017-13.895-5.934-6.862-11.143-17.856-9.725-28.376 10.291-0.796 20.794 5.227 27.201 12.984" />
            <path d="m305.32 379c-15.216 1.128-28.132-8.503-35.334-8.503-7.312 0-18.578 8.26-30.528 8.035-15.686-0.23-30.147-9.132-38.236-23.177-16.296-28.284-4.16-70.196 11.714-93.15 7.759-11.218 17.024-23.852 29.186-23.392 11.704 0.457 16.137 7.566 30.287 7.566 14.144 0 18.134-7.566 30.514-7.338 12.601 0.229 20.584 11.446 28.29 22.726 8.918 13.016 12.588 25.621 12.803 26.284-0.281 0.1-24.559 9.423-24.805 37.401-0.22 23.392 19.09 34.637 19.973 35.181-10.968 16.142-27.899 17.926-33.864 18.367" />
            <path d="m295.92 394.15c6.464 7.812 10.814 18.692 9.62 29.515-9.308-0.369-20.557-6.199-27.232-14.005-5.974-6.917-11.223-17.994-9.796-28.591 10.366-0.805 20.952 5.266 27.408 13.081" />
          </g>
          <g class="icon-device" transform="matrix(.26458 0 0 .26458 -254.39 76.818)" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2">
            <rect x="2" y="3" width="20" height="14" rx="2" ry="2" />
            <line x1="8" x2="16" y1="21" y2="21" />
            <line x1="12" x2="12" y1="17" y2="21" />
          </g>
        </g>
      </svg>
    </div>
    <div v-else-if="(deviceType == 'pc' || deviceType == 'desktop') && (deviceOS == 'win' || deviceOS == 'windows' )">
      <svg class="feather table-icon-device" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" xmlns:cc="http://creativecommons.org/ns#" xmlns:dc="http://purl.org/dc/elements/1.1/" xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#">
        <g transform="matrix(3.7795 0 0 3.7795 1012 -279.09)">
          <g class="icon-device" transform="matrix(.26458 0 0 .26458 -267.76 73.841)" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2">
            <rect x="2" y="3" width="20" height="14" rx="2" ry="2" />
            <line x1="8" x2="16" y1="21" y2="21" />
            <line x1="12" x2="12" y1="17" y2="21" />
          </g>
          <g transform="matrix(.012641 0 0 .012641 -265.66 75.53)">
            <path d="m170.41 21.125c-32.996 13.642-48.861 5.973-63.16-3.65l-16.278 56.462c14.285 9.678 31.531 17.635 63.188 3.463z" />
            <path d="m63.142 134.63c-14.331-9.645-29.91-17.578-62.984-3.902l16.195-56.568c33.081-13.678 48.973-5.938 63.29 3.766l-16.501 56.703z" />
            <path d="m82.393 67.715c-8.628-5.81-17.907-11.233-31.085-11.333-8.695-0.067-19.045 2.403-32.184 7.836l16.283-56.422c33.059-13.669 48.938-5.933 63.245 3.764z" />
            <path d="m88.227 83.369c14.313 9.637 30.212 17.313 63.243 3.66l-16.281 56.234c-33.037 13.664-48.903 5.926-63.201-3.77z" />
          </g>
        </g>
      </svg>
    </div>
    <div v-else-if="(deviceType == 'pc' || deviceType == 'desktop')">
      <svg class="feather table-icon-device" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" xmlns:cc="http://creativecommons.org/ns#" xmlns:dc="http://purl.org/dc/elements/1.1/" xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#">
        <g class="icon-device" transform="translate(2.8411e-5 -.0071219)" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2">
          <rect x="2" y="3" width="20" height="14" rx="2" ry="2" />
          <line x1="8" x2="16" y1="21" y2="21" />
          <line x1="12" x2="12" y1="17" y2="21" />
        </g>
      </svg>
    </div>
    <div v-if="deviceType == 'tablet' && deviceOS == 'android'">
      <svg class="feather table-icon-device" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" xmlns:cc="http://creativecommons.org/ns#" xmlns:dc="http://purl.org/dc/elements/1.1/" xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#">
        <g transform="matrix(3.7795 0 0 3.7795 997.71 -245.16)">
          <g class="icon-device" transform="matrix(.26458 0 0 .26458 -256.4 64.021)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2">
            <rect transform="scale(-1)" x="7.1603" y="-25.194" width="18.968" height="20" rx="2.3709" ry="2" />
            <line x1="-16.644" x2="-16.644" y1="21.194" y2="21.194" />
          </g>
          <g transform="matrix(.0035256 0 0 .0035256 -261.88 66.46)">
            <path d="m152.68 458.36c0 15.3 10.2 25.5 25.5 25.5h25.5v89.25c0 20.4 17.85 38.25 38.25 38.25s38.25-17.85 38.25-38.25v-89.25h51v89.25c0 20.4 17.85 38.25 38.25 38.25s38.25-17.85 38.25-38.25v-89.25h25.5c15.3 0 25.5-10.2 25.5-25.5v-255h-306zm-63.75-255c-20.4 0-38.25 17.851-38.25 38.25v178.5c0 20.4 17.85 38.25 38.25 38.25s38.25-17.85 38.25-38.25v-178.5c0-20.4-17.85-38.25-38.25-38.25zm433.5 0c-20.4 0-38.25 17.851-38.25 38.25v178.5c0 20.4 17.85 38.25 38.25 38.25s38.25-17.85 38.25-38.25v-178.5c0-20.4-17.85-38.25-38.25-38.25zm-127.5-147.9 33.15-33.15c5.1-5.1 5.1-12.75 0-17.85-5.101-5.101-12.75-5.101-17.851 0l-38.25 38.25c-17.85-12.75-40.8-17.851-66.3-17.851s-48.45 5.101-68.85 15.3l-35.7-38.25c-5.1-2.55-15.3-2.55-20.4 0-2.55 5.101-2.55 15.301 0 20.4l33.15 33.15c-35.7 28.05-61.2 71.399-61.2 122.4h306c1e-3 -50.998-25.499-96.898-63.749-122.4zm-140.25 71.4h-25.5v-25.5h25.5zm127.5 0h-25.5v-25.5h25.5z" />
          </g>
        </g>
      </svg>
    </div>
    <div v-else-if="deviceType == 'tablet' && deviceOS == 'apple'">
      <svg class="feather table-icon-device" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" xmlns:cc="http://creativecommons.org/ns#" xmlns:dc="http://purl.org/dc/elements/1.1/" xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#">
        <g transform="matrix(3.7795 0 0 3.7795 949.32 -297.84)">
          <g class="icon-device" transform="matrix(.26458 0 0 .26458 -243.6 77.959)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2">
            <rect transform="scale(-1)" x="7.1603" y="-25.194" width="18.968" height="20" rx="2.3709" ry="2" />
            <line x1="-16.644" x2="-16.644" y1="21.194" y2="21.194" />
          </g>
          <g transform="matrix(.011641 0 0 -.011641 -251.16 85.139)" stroke="none">
            <path d="m310.46 382.58c-15.106 1.119-27.922-8.435-35.071-8.435-7.25 0-18.437 8.191-30.291 7.968-15.576-0.226-29.923-9.061-37.955-23.002-16.172-28.069-4.125-69.661 11.626-92.444 7.703-11.134 16.893-23.677 28.967-23.216 11.621 0.461 16.014 7.514 30.058 7.514 14.035 0 17.994-7.514 30.283-7.29 12.508 0.237 20.429 11.362 28.08 22.549 8.851 12.922 12.486 25.437 12.705 26.097-0.277 0.104-24.375 9.352-24.621 37.116-0.215 23.216 18.951 34.377 19.824 34.914-10.88 16.022-27.689 17.788-33.605 18.229" />
            <path d="m301.14 397.61c6.412 7.751 10.731 18.552 9.541 29.287-9.229-0.362-20.398-6.146-27.017-13.895-5.934-6.862-11.143-17.856-9.725-28.376 10.291-0.796 20.794 5.227 27.201 12.984" />
            <path d="m305.32 379c-15.216 1.128-28.132-8.503-35.334-8.503-7.312 0-18.578 8.26-30.528 8.035-15.686-0.23-30.147-9.132-38.236-23.177-16.296-28.284-4.16-70.196 11.714-93.15 7.759-11.218 17.024-23.852 29.186-23.392 11.704 0.457 16.137 7.566 30.287 7.566 14.144 0 18.134-7.566 30.514-7.338 12.601 0.229 20.584 11.446 28.29 22.726 8.918 13.016 12.588 25.621 12.803 26.284-0.281 0.1-24.559 9.423-24.805 37.401-0.22 23.392 19.09 34.637 19.973 35.181-10.968 16.142-27.899 17.926-33.864 18.367" />
            <path d="m295.92 394.15c6.464 7.812 10.814 18.692 9.62 29.515-9.308-0.369-20.557-6.199-27.232-14.005-5.974-6.917-11.223-17.994-9.796-28.591 10.366-0.805 20.952 5.266 27.408 13.081" />
          </g>
        </g>
      </svg>
    </div>
    <div v-else-if="deviceType == 'tablet'">
      <svg class="feather table-icon-device" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" xmlns:cc="http://creativecommons.org/ns#" xmlns:dc="http://purl.org/dc/elements/1.1/" xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#">
        <g class="icon-device" transform="translate(28.644 -3.1942)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2">
          <rect transform="scale(-1)" x="7.1603" y="-25.194" width="18.968" height="20" rx="2.3709" ry="2" />
          <line x1="-16.644" x2="-16.644" y1="21.194" y2="21.194" />
        </g>
      </svg>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    deviceType: { type: String, required: true },
    deviceOS: { type: String, required: true }
  },
  data() {
    return {
    }
  }
}
</script>
<style scoped>
.table-icon-device {
  width: 4rem;
}
.table-icon-device .icon-device {
  stroke: rgb(184, 184, 184);
}
.table-icon-device path {
  fill: rgb(134, 134, 134);
}
</style>